export default {
	"options": {
		"rival": "Rival",
	},	
	"rival": {
		"female": [
			"Hallowed",
			"Crusaders of",
			"Warrior",
			"The",
			"Harbinger",
			"Children of",
			"Dirty",
			"Blade",
			"Champions of",
			"Minions of",
			"Storm",
			"Dark",
			"Iron",
			"Brave",
			"The Guild of",
			"Blood",
			"Chaotic",
			"Club",
			"Independent",
			"Cult of",
			"Doom",
			"Dread",
			"Space",
			"Reaper",
			"Howling"
		],	
		"surname": [
			"Saints",
			"Titans",
			"Chaos",
			"Guard",
			"Boys",
			"Raiders",
			"Rats",
			"Army",
			"Elites",
			"Imperitive",
			"Warlords",
			"Sanctuary",
			"Shadow",
			"Light",
			"Republic",
			"Legion",
			"Alliance",
			"Torment",
			"Knights",
			"Brotherhood",
			"Fist",
			"Wardens",
			"Wolves",
			"Hordes",
			"Dominion",			
		]
	}
}
