export const postBattleTables = [
    {
        key: 'battlefieldfinds',
        title: 'Battlefield Finds',
        tables: {
            default: [
                { "label": "Weapon", "weight": 15 },
                { "label": "Usable goods", "subtable": "consumables", "weight": 10 },
                { "label": "Curious data stick/Invastion Evidence", "weight": 10 },
                { "label": "Starship part", "weight": 10 },
                { "label": "Personal trinket", "weight": 15 },
                { "label": "Debris", "weight": 15 },
                { "label": "Vital info/Invasion Evidence", "weight": 15 },
                { "label": "Nothing of value", "weight": 10 },
            ],
            consumables: [
                { "label": "Booster Pills", "weight": 20 },
                { "label": "Combat Serum", "weight": 10 },
                { "label": "Kiranan Crystals", "weight": 10 },
                { "label": "Rage Out", "weight": 14 },
                { "label": "Still", "weight": 15 },
                { "label": "Stim-pack", "weight": 30 },
            ],
        }
    },
    {
        key: 'loottable',
        title: 'Lot Table',
        tables: {
            default: [
                { "label": "Weapon", "subtable": "weapons", "weight": 25 },
                { "label": "Damaged weapons", "subtable": "weapons", "weight": 10 },
                { "label": "Damaged gear", "subtable": "gear", "weight": 10 },
                { "label": "Gear", "subtable": "gear", "weight": 20 },
                { "label": "Odds and ends", "subtable": "oddsandends", "weight": 15 },
                { "label": "Rewards", "subtable": "rewards", "weight": 20 },                
            ],
            weapons: [
                { "label": "Slug Weapons", "weight": 35, "subtable": "slugweapons" },
                { "label": "Energy Weapons", "weight": 15, "subtable": "energyweapons" },
                { "label": "Special Weapons", "weight": 15, "subtable": "specialweapons" },
                { "label": "Melee Weapons", "weight": 20, "subtable": "meleeweapons" },
                { "label": "Grenades", "weight": 15, "subtable": "grenades" },                
            ],
            energyweapons: [
                { "label": "Hand Laser", "weight": 20 },
                { "label": "Beam Pistol", "weight": 15 },
                { "label": "Infantry Laser", "weight": 20 },
                { "label": "Blast Pistol", "weight": 15 },
                { "label": "Blast Rifle", "weight": 20 },
                { "label": "Hyper Blaster", "weight": 10 },
            ],
            slugweapons: [
                { "label": "Hold Out Pistol", "weight": 5 },
                { "label": "Hand Gun", "weight": 8 },
                { "label": "Scrap Pistol", "weight": 5 },
                { "label": "Machine Pistol", "weight": 8 },
                { "label": "Duelling Pistol", "weight": 6 },
                { "label": "Hand Cannon", "weight": 5 },
                { "label": "Colony Rifle", "weight": 9 },
                { "label": "Military Rifle", "weight": 10 },
                { "label": "Shotgun", "weight": 9 },
                { "label": "Flak Gun", "weight": 5 },
                { "label": "Hunting Rifle", "weight": 8 },
                { "label": "Marksman's Rifle", "weight": 5 },
                { "label": "Auto Rifle", "weight": 9 },
                { "label": "Rattle Gun", "weight": 8 },
            ],
            specialweapons: [
                { "label": "Needle Rifle", "weight": 20 },
                { "label": "Plasma Rifle", "weight": 25 },
                { "label": "Fury Rifle", "weight": 15 },
                { "label": "Shell Gun", "weight": 10 },
                { "label": "Cling Fire Pistol", "weight": 25 },
                { "label": "Hand Flamer", "weight": 10 },
            ],
            meleeweapons: [
                { "label": "Blade", "weight": 20 },
                { "label": "Brutal Melee Weapon", "weight": 20 },
                { "label": "Boarding Saber", "weight": 15 },
                { "label": "Ripper Sword", "weight": 20 },
                { "label": "Shatter Axe", "weight": 10 },
                { "label": "Power Claw", "weight": 5 },
                { "label": "Glare Sword", "weight": 5 },
                { "label": "Supression Maul", "weight": 5 },
            ],
            grenades: [
                { "label": "3 Frakk Grenades", "weight": 60 },
                { "label": "3 Dazzle Grenades", "weight": 40 },
            ],
            gear: [
                { "label": "Gun Mods", "subtable": "gunmods", "weight": 20 },
                { "label": "Gun Sights", "subtable": "gunsights", "weight": 20 },
                { "label": "Protective Items", "subtable": "protectiveitems", "weight": 35 },
                { "label": "Utility Items", "subtable": "utilityitems", "weight": 25 },
            ],
            gunmods: [
                { "label": "Assault Blade", "weight": 10 },
                { "label": "Beam Light", "weight": 10 },
                { "label": "Bipod", "weight": 15 },
                { "label": "Hot Shot Pack", "weight": 20 },
                { "label": "Cyber-configurable Nano-sludge", "weight": 10 },
                { "label": "Stabliizer", "weight": 15 },
                { "label": "Shock Attachment", "weight": 10 },
                { "label": "Upgrade Kit", "weight": 10 },
            ],
            gunsights: [
                { "label": "Laser Sight", "weight": 20 },
                { "label": "Quality Sight", "weight": 25 },
                { "label": "Seeker Sight", "weight": 25 },
                { "label": "Tracker Sight", "weight": 15 },
                { "label": "Unity Battle Sight", "weight": 15 },
            ],
            protectiveitems: [
                { "label": "Battle Dress", "weight": 5 },
                { "label": "Camo Cloak", "weight": 10 },
                { "label": "Combat Armor", "weight": 25 },
                { "label": "Deflector Field", "weight": 10 },
                { "label": "Flak Screen", "weight": 15 },
                { "label": "Flex-Armor", "weight": 10 },
                { "label": "Frag Vest", "weight": 15 },
                { "label": "Screen Generator", "weight": 5 },
                { "label": "Stealth Gear", "weight": 5 },
            ],
            utilityitems: [
                { "label": "Auto Sensor", "weight": 6 },
                { "label": "Battle Visor", "weight": 5 },
                { "label": "Communicator", "weight": 6 },
                { "label": "Concealed Blade", "weight": 6 },
                { "label": "Displacer", "weight": 6 },
                { "label": "Distraction Bot", "weight": 5 },
                { "label": "Grapple Launcher", "weight": 4 },
                { "label": "Grav Dampener", "weight": 5 },
                { "label": "Hazard Suit", "weight": 6 },
                { "label": "Hover Board", "weight": 5 },
                { "label": "Insta-Wall", "weight": 3 },
                { "label": "Jump Belt", "weight": 6 },
                { "label": "Motion Tracker", "weight": 7 },
                { "label": "Multi-Cutter", "weight": 5 },
                { "label": "Robo-Rabbit's Foot", "weight": 4 },
                { "label": "Scanner Bot", "weight": 5 },
                { "label": "Snooper Bot", "weight": 5 },
                { "label": "Sonic Emitter", "weight": 4 },
                { "label": "Steel Boots", "weight": 3 },
                { "label": "Time Distorter", "weight": 4 },
            ],
            oddsandends: [
                { "label": "Consumables", "subtable": "consumables", "weight": 55 },
                { "label": "Implants", "subtable": "implants", "weight": 15 },
                { "label": "Ship Items", "subtable": "shipitems", "weight": 30 },
            ],
            consumables: [
                { "label": "Booster Pills", "weight": 20 },
                { "label": "Combat Serum", "weight": 10 },
                { "label": "Kiranan Crystals", "weight": 10 },
                { "label": "Rage Out", "weight": 14 },
                { "label": "Still", "weight": 15 },
                { "label": "Stim-pack", "weight": 30 },
            ],
            implants: [
                { "label": "AI Compannion", "weight": 10 },
                { "label": "Body Wire", "weight": 6 },
                { "label": "Boosted Arm", "weight": 12 },
                { "label": "Boosted Leg", "weight": 12 },
                { "label": "Cyber Hand", "weight": 10 },
                { "label": "Genetic Defenses", "weight": 11 },
                { "label": "Health Boost", "weight": 10 },
                { "label": "Nerve Adjuster", "weight": 8 },
                { "label": "Neural Optimization", "weight": 6 },
                { "label": "Night Sight", "weight": 9 },
                { "label": "Pain Supressor", "weight": 6 },
            ],
            shipitems: [
                { "label": "Analyzer", "weight": 4 },
                { "label": "Colonist Ration Pack", "weight": 7 },
                { "label": "Duplicator", "weight": 6 },
                { "label": "Fake ID", "weight": 7 },
                { "label": "Fixer", "weight": 7 },
                { "label": "Genetic Reconfiguration Kit", "weight": 3 },
                { "label": "Loaded Dice", "weight": 5 },
                { "label": "Lucky Dice", "weight": 5 },
                { "label": "Mk II Translator", "weight": 4 },
                { "label": "Med-patch", "weight": 8 },
                { "label": "Meditation Orb", "weight": 4 },
                { "label": "Nano-doc", "weight": 7 },
                { "label": "Novelty Stuffed Animal", "weight": 4 },
                { "label": "Purifier", "weight": 3 },
                { "label": "Repair Bot", "weight": 4 },
                { "label": "Sector Permit", "weight": 5 },
                { "label": "Spare Parts", "weight": 8 },
                { "label": "Teach-Bot", "weight": 5 },
                { "label": "Transcender", "weight": 4 },
            ],
            rewards: [
                { "label": "Documents", "weight": 10 },
                { "label": "Data Files", "weight": 10 },
                { "label": "Scrap", "weight": 5 },
                { "label": "Cargo Crate", "weight": 15 },
                { "label": "Valuable Materials", "weight": 15 },
                { "label": "Rare Substance", "weight": 15 },
                { "label": "Ship Parts", "weight": 15 },
                { "label": "Military Ship Part", "weight": 5 },
                { "label": "Mysterious Items", "weight": 5 },
                { "label": "Personal Item", "weight": 5 },
            ]
        }
    },
    {
        key: 'injurytable',
        title: 'Injury Table',
        tables: {
            default: [
                { "label": "Gruesome fate", "weight": 5 },
                { "label": "Death or Permanent Injury", "weight": 10 },
                { "label": "Miraculous escape", "weight": 1 },
                { "label": "Equipment loss", "weight": 14 },
                { "label": "Crippling wound", "weight": 15 },
                { "label": "Serious injury", "weight": 9 },
                { "label": "Minor injuries", "weight": 26 },
                { "label": "Knocked out", "weight": 15 },
                { "label": "School of hard knocks", "weight": 5 },
            ],
            botinjury: [
                { "label": "Obliterated", "weight": 5 },
                { "label": "Destroyed", "weight": 10 },
                { "label": "Equipment loss", "weight": 15 },
                { "label": "Severe damage", "weight": 15 },
                { "label": "Minor damage", "weight": 20 },
                { "label": "Just a few dents", "weight": 35 },
            ],
        }
    },
    {
        key: 'militaryweapon',
        title: 'Military Weapon',
        tables: {
            default: [
                { "label": "Military Rifle", "weight": 25 },
                { "label": "Infantry Laser", "weight": 20 },
                { "label": "Marksman's Rifle", "weight": 5 },
                { "label": "Needle Rifle", "weight": 10 },
                { "label": "Auto Rifle", "weight":  15 },
                { "label": "Rattle Gun", "weight": 5 },
                { "label": "Boarding Saber", "weight": 15 },
                { "label": "Shatter Axe", "weight": 5 },                
            ],            
        }
    },
    {
        key: 'geartable',
        title: 'Gear Table',
        tables: {
            default: [
                { "label": "Assault Blade", "weight": 4 },
                { "label": "Beam Light", "weight": 6 },
                { "label": "Bipod", "weight": 5 },
                { "label": "Booster Pills", "weight": 5 },
                { "label": "Camo Cloak", "weight": 4 },
                { "label": "Combat Armor", "weight": 4 },
                { "label": "Communicator", "weight": 5 },
                { "label": "Concealed Blade", "weight": 4 },
                { "label": "Fake Id", "weight": 5 },
                { "label": "Fixer", "weight": 4 },
                { "label": "Frag Vest", "weight": 6 },
                { "label": "Grapple Launcher", "weight": 5 },
                { "label": "Hazard Suit", "weight": 4 },
                { "label": "Laser Sight", "weight": 4 },
                { "label": "Loaded Dice", "weight": 4 },
                { "label": "Med-patch", "weight": 6 },
                { "label": "Nano-doc", "weight": 6 },
                { "label": "Purifier", "weight": 4 },
                { "label": "Scanner Bot", "weight": 4 },
                { "label": "Sector Permit", "weight": 3 },
                { "label": "Steel Boots", "weight": 4 },
                { "label": "Tracker Sight", "weight": 4 },
            ],
        }
    },
    {
        key: 'gadgettable',
        title: 'Gadget Table',
        tables: {
            default: [
                { "label": "AI Companion", "weight": 4 },
                { "label": "Analyzer", "weight": 5 },
                { "label": "Battle Visor", "weight": 4 },
                { "label": "Boosted Arm", "weight": 4 },
                { "label": "Boosted Leg", "weight": 4 },
                { "label": "Cyber Hand", "weight": 3 },
                { "label": "Displacer", "weight": 3 },
                { "label": "Distraction Bot", "weight": 4 },
                { "label": "Duplicator", "weight": 5 },
                { "label": "Insta-Wall", "weight": 5 },
                { "label": "Jump Belt", "weight": 5 },
                { "label": "Nerve Adjuster", "weight": 4 },
                { "label": "Repair Bot", "weight": 5 },
                { "label": "Scanner Bot", "weight": 5 },
                { "label": "Screen Generator", "weight": 5 },
                { "label": "Seeker Sight", "weight": 4 },
                { "label": "Shock Attachment", "weight": 4 },
                { "label": "Snooper Bot", "weight": 6 },
                { "label": "Sonic Emitter", "weight": 4 },
                { "label": "Stabilizer", "weight": 6 },
                { "label": "Stealth Gear", "weight": 4 },
                { "label": "Stim-pack", "weight": 7 },
            ],            
        }
    },
]