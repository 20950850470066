


















import Vue from 'vue';
import { CampaignStepResult }  from '../js/fiveParsecs';
import { Step, SubStep } from "../js/fiveParsecsEnums";
import StepDetails from '@/components/StepDetails.vue';

export default Vue.extend({
  name: "Travel",
  components: { 
    StepDetails,   
  },
  mounted() {   
  }, 
  data() {
    return {
      activeStep: null as CampaignStepResult|null,
      steps: [
        new CampaignStepResult(Step.Travel, SubStep.FleeInvasion, this),
        new CampaignStepResult(Step.Travel, SubStep.DecideToTravel, this),
        new CampaignStepResult(Step.Travel, SubStep.NewWorldArrival, this),    
      ] as Array<CampaignStepResult>      
    }
  },    
  computed : {
    username: function() {
      return this.$store.state.user.username;
    },
  },
  methods: {       
    setActiveStep(step: CampaignStepResult|null) {
      this.activeStep = step;
      const activeStepElem: HTMLElement|null = document.getElementById("activeStep");
      activeStepElem?.scrollIntoView(); 
    },
    resolveActiveStep() {
      this.activeStep?.processStep();
    }
  }
})
