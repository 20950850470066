export const battleTables = [
    {
        key: 'deploymentconditions',
        title: 'Deployment Conditions',
        sequence: ["default", "rival", "quest"],
        tables: {
            default: [
                { "label": "None", "weight": 40 },
                { "label": "Small encounter", "weight": 5 },
                { "label": "Poor visibility", "weight": 5 },
                { "label": "Brief engagement", "weight": 5 },
                { "label": "Toxic environment", "weight": 5 },
                { "label": "Surprise encounter", "weight": 5 },
                { "label": "Delayed", "weight": 10 },
                { "label": "Slippery ground", "weight": 5 },
                { "label": "Bitter struggle", "weight": 5 },
                { "label": "Caught off guard", "weight": 5 },
                { "label": "Gloomy", "weight": 10 },
            ],
            rival: [
                { "label": "None", "weight": 10 },
                { "label": "Small encounter", "weight": 5 },
                { "label": "Poor visibility", "weight": 5 },
                { "label": "Brief engagement", "weight": 5 },
                { "label": "Toxic environment", "weight": 5 },
                { "label": "Surprise encounter", "weight": 15 },
                { "label": "Delayed", "weight": 5 },
                { "label": "Slippery ground", "weight": 10 },
                { "label": "Bitter struggle", "weight": 15 },
                { "label": "Caught off guard", "weight": 15 },
                { "label": "Gloomy", "weight": 10 },
            ],
            quest: [
                { "label": "None", "weight": 5 },
                { "label": "Small encounter", "weight": 5 },
                { "label": "Poor visibility", "weight": 15 },
                { "label": "Brief engagement", "weight": 5 },
                { "label": "Toxic environment", "weight": 10 },
                { "label": "Surprise encounter", "weight": 10 },
                { "label": "Delayed", "weight": 10 },
                { "label": "Slippery ground", "weight": 5 },
                { "label": "Bitter struggle", "weight": 15 },
                { "label": "Caught off guard", "weight": 10 },
                { "label": "Gloomy", "weight": 10 },
            ],

        }
    },
    {
        key: 'notablesights',
        title: 'Notable Sights',
        sequence: ["default", "rival", "quest"],
        tables: {
            default: [
                { "label": "Nothing special", "weight": 20 },
                { "label": "Documentation", "weight": 10 },
                { "label": "Priority target", "weight": 10 },
                { "label": "Loot cache", "weight": 10 },
                { "label": "Shiny bits", "weight": 10 },
                { "label": "Really shiny bits", "weight": 10 },
                { "label": "Person of interest", "weight": 10 },
                { "label": "Peculiar item", "weight": 10 },
                { "label": "Curious Item", "weight": 10 },
            ],
            rival: [
                { "label": "Nothing special", "weight": 40 },
                { "label": "Documentation", "weight": 10 },
                { "label": "Priority target", "weight": 10 },
                { "label": "Loot cache", "weight": 10 },
                { "label": "Shiny bits", "weight": 5 },
                { "label": "Really shiny bits", "weight": 5 },
                { "label": "Person of interest", "weight": 10 },
                { "label": "Peculiar item", "weight": 5 },
                { "label": "Curious Item", "weight": 5 },
            ],
            quest: [
                { "label": "Nothing special", "weight": 10 },
                { "label": "Documentation", "weight": 15 },
                { "label": "Priority target", "weight": 10 },
                { "label": "Loot cache", "weight": 15 },
                { "label": "Shiny bits", "weight": 5 },
                { "label": "Really shiny bits", "weight": 10 },
                { "label": "Person of interest", "weight": 15 },
                { "label": "Peculiar item", "weight": 10 },
                { "label": "Curious Item", "weight": 10 },
            ],

        }
    },
    {
        key: 'missionobjective',
        title: 'Mission Objective',
        sequence: ["patron", "opportunity", "quest"],
        tables: {
            patron: [
                { "label": "Deliver", "weight": 2 },
                { "label": "Eliminate", "weight": 1 },
                { "label": "Move Through", "weight": 2 },
                { "label": "Secure", "weight": 2 },
                { "label": "Protect", "weight": 1 },
                { "label": "Fight Off", "weight": 2 },
            ],
            opportunity: [
                { "label": "Move Through", "weight": 2 },
                { "label": "Deliver", "weight": 2 },
                { "label": "Access", "weight": 2 },
                { "label": "Patrol", "weight": 2 },            
                { "label": "Fight Off", "weight": 2 },
            ],
            quest: [
                { "label": "Move Through", "weight": 2 },
                { "label": "Search", "weight": 2 },
                { "label": "Defend", "weight": 2 },
                { "label": "Acquire", "weight": 2 },            
                { "label": "Fight Off", "weight": 2 },
            ],

        }
    },
    {
        key: 'rivalattack',
        title: 'Rival Attack',        
        tables: {
            weaponA: [
                { "label": "Ambush", "weight": 1 },
                { "label": "Brought friends", "weight": 2 },
                { "label": "Showdown", "weight": 2 },
                { "label": "Assault", "weight": 1 },
                { "label": "Raid", "weight": 1 },                
            ],            
        }
    },
    {
        key: 'enemyencountercategory',
        title: 'Enemy Encounter Category',
        sequence: ["patron", "opportunity", "quest", "unknownrival"],
        tables: {
            patron: [
                { "label": "Criminal Elements", "subtable": "criminalelements", "weight": 25 },
                { "label": "Hired Muscle", "subtable": "hiredmuscle", "weight": 25 },
                { "label": "Interested Parties", "subtable": "interestedparties", "weight": 25 },
                { "label": "Roving Threats", "subtable": "rovingthreats", "weight": 25 },
            ],
            opportunity: [
                { "label": "Criminal Elements", "subtable": "criminalelements", "weight": 30 },
                { "label": "Hired Muscle", "subtable": "hiredmuscle", "weight": 30 },
                { "label": "Interested Parties", "subtable": "interestedparties", "weight": 20 },
                { "label": "Roving Threats", "subtable": "rovingthreats", "weight": 20 },
            ],
            quest: [
                { "label": "Criminal Elements", "subtable": "criminalelements", "weight": 15 },
                { "label": "Hired Muscle", "subtable": "hiredmuscle", "weight": 25 },
                { "label": "Interested Parties", "subtable": "interestedparties", "weight": 30 },
                { "label": "Roving Threats", "subtable": "rovingthreats", "weight": 30 },
            ],
            unknownrival: [
                { "label": "Criminal Elements", "subtable": "criminalelements", "weight": 50 },
                { "label": "Hired Muscle", "subtable": "hiredmuscle", "weight": 30 },
                { "label": "Interested Parties", "subtable": "rovingthreats", "weight": 20 },               
            ],
            criminalelements: [                
                { "label": "Gangers"         , "description": "{ \"numbers\": 2, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"A\", \"weapons\": \"1A\" }", "weight": 10 },
                { "label": "Punks"           , "description": "{ \"numbers\": 3, \"panic\": \"1-3\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"A\", \"weapons\": \"1A\" }", "weight": 9  },
                { "label": "Raiders"         , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"3\", \"ai\": \"A\", \"weapons\": \"2A\" }", "weight": 8  },
                { "label": "Cultists"        , "description": "{ \"numbers\": 2, \"panic\": \"1\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"A\", \"weapons\": \"1A\" }"  , "weight": 7  },
                { "label": "Psychos"         , "description": "{ \"numbers\": 2, \"panic\": \"1\", \"speed\": \"6\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"R\", \"weapons\": \"1B\" }"  , "weight": 9  },
                { "label": "Brat Gang"       , "description": "{ \"numbers\": 2, \"panic\": \"1-3\", \"speed\": \"5\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"A\", \"weapons\": \"2C\" }", "weight": 5  },
                { "label": "Gene Renegade"   , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"5\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"C\", \"weapons\": \"1B\" }", "weight": 3  },
                { "label": "Anarchists"      , "description": "{ \"numbers\": 2, \"panic\": \"1-2\", \"speed\": \"5\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"A\", \"weapons\": \"2B\" }", "weight": 6  },
                { "label": "Pirates"         , "description": "{ \"numbers\": 2, \"panic\": \"1-3\", \"speed\": \"5\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"A\", \"weapons\": \"2A\" }", "weight": 7  },
                { "label": "K'Erin Outlaws"  , "description": "{ \"numbers\": 1, \"panic\": \"1\", \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"A\", \"weapons\": \"2A\" }"  , "weight": 7  },
                { "label": "Skulker Brigands", "description": "{ \"numbers\": 3, \"panic\": \"1-2\", \"speed\": \"6\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"C\", \"weapons\": \"1B\" }", "weight": 8  },
                { "label": "Tech Gangers"    , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"T\", \"weapons\": \"3C\" }", "weight": 4  },
                { "label": "Starport Scum"   , "description": "{ \"numbers\": 3, \"panic\": \"1-3\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"D\", \"weapons\": \"1A\" }", "weight": 7  },
                { "label": "Hulker Gang"     , "description": "{ \"numbers\": 0, \"panic\": \"1\", \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"5\", \"ai\": \"A\", \"weapons\": \"1A\" }"  , "weight": 4  },
                { "label": "Gun Slingers"    , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"3\", \"ai\": \"T\", \"weapons\": \"1B\" }", "weight": 6  },
            ],
            hiredmuscle: [
                { "label": "Unknown Mercs"       , "description": "{ \"numbers\": 0, \"panic\": \"1-2\", \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"T\", \"weapons\": \"2B\" }" , "weight": 15 },
                { "label": "Enforcers"           , "description": "{ \"numbers\": 0, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"T\", \"weapons\": \"2A\" }" , "weight": 12 },
                { "label": "Guild Troops"        , "description": "{ \"numbers\": 0, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"T\", \"weapons\": \"2C\" }" , "weight": 8  },
                { "label": "Roid-gangers"        , "description": "{ \"numbers\": 1, \"panic\": \"1\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"5\", \"ai\": \"A\", \"weapons\": \"1A\" }"   , "weight": 5  },
                { "label": "Black Ops Team"      , "description": "{ \"numbers\": 0, \"panic\": \"1\", \"speed\": \"6\", \"combat\": \"2\", \"toughness\": \"5\", \"ai\": \"T\", \"weapons\": \"3A\" }"   , "weight": 3  },
                { "label": "War Bots"            , "description": "{ \"numbers\": 0, \"panic\": \"0\", \"speed\": \"3\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"A\", \"weapons\": \"3C\" }"   , "weight": 4  },
                { "label": "Secret Agents"       , "description": "{ \"numbers\": 0, \"panic\": \"1-2\", \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"C\", \"weapons\": \"2C\" }" , "weight": 4  },
                { "label": "Assassins"           , "description": "{ \"numbers\": 0, \"panic\": \"1\", \"speed\": \"6\", \"combat\": \"2\", \"toughness\": \"3\", \"ai\": \"A\", \"weapons\": \"1B\" }"   , "weight": 3  },
                { "label": "Feral Mercenaries"   , "description": "{ \"numbers\": 2, \"panic\": \"1-2\", \"speed\": \"5\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"A\", \"weapons\": \"2B\" }" , "weight": 6  },
                { "label": "Skulker Mercenaries" , "description": "{ \"numbers\": 3, \"panic\": \"1-2\", \"speed\": \"7\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"C\", \"weapons\": \"2C\" }" , "weight": 5  },
                { "label": "Corporate Security"  , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"D\", \"weapons\": \"2B\" }" , "weight": 10 },
                { "label": "Unity Grunts"        , "description": "{ \"numbers\": 1, \"panic\": \"1\", \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"T\", \"weapons\": \"2C\" }"   , "weight": 5  },
                { "label": "Security Bots"       , "description": "{ \"numbers\": 1, \"panic\": \"1\", \"speed\": \"3\", \"combat\": \"0\", \"toughness\": \"5\", \"ai\": \"D\",\"weapons\": \"2A\" }"    , "weight": 6  },
                { "label": "Black Dragon Mercs"  , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"T\", \"weapons\": \"2C\" }" , "weight": 5  },
                { "label": "Rage Lizard Mercs"   , "description": "{ \"numbers\": 0, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"5\", \"ai\": \"T\", \"weapons\": \"3B\" }" , "weight": 5  },
                { "label": "Blood Storm Mercs"   , "description": "{ \"numbers\": 0, \"panic\": \"1\", \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"A\", \"weapons\": \"2B\" }"   , "weight": 5  },
                ],
            interestedparties: [                
                { "label": "Renegade Soldiers"  , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"5\", \"ai\": \"T\", \"weapons\": \"2B\" }"                       , "weight": 7  },
                { "label": "Bounty Hunters"     , "description": "{ \"numbers\": 0, \"panic\": \"1-2\", \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"T\", \"weapons\": \"1B\" }"                       , "weight": 7  },
                { "label": "Abandoned"          , "description": "{ \"numbers\": 1, \"panic\": \"1-3\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"A\", \"weapons\": \"1A\" }"                       , "weight": 5  },
                { "label": "Vigilantes"         , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"A\", \"weapons\": \"2A\" }"                       , "weight": 9  },
                { "label": "Isolationists"      , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"C\", \"weapons\": \"1A\" }"                       , "weight": 8  },
                { "label": "Zealots"            , "description": "{ \"numbers\": 2, \"panic\": \"1\", \"speed\": \"5\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"A\", \"weapons\": \"1A\" }"                         , "weight": 6  },
                { "label": "Mutants"            , "description": "{ \"numbers\": 3, \"panic\": \"1-3\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"5\", \"ai\": \"A\", \"weapons\": \"1A\" }"                       , "weight": 7  },
                { "label": "Primitives"         , "description": "{ \"numbers\": 2, \"panic\": \"1-2\", \"speed\": \"6\", \"combat\": \"1\", \"toughness\": \"3\", \"ai\": \"A\", \"weapons\": \"Blade,Brutal Melee Weapon\" }", "weight": 4  },
                { "label": "Precursor Exiles"   , "description": "{ \"numbers\": 0, \"panic\": \"1-2\", \"speed\": \"6\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"T\", \"weapons\": \"3B\" }"                       , "weight": 4  },
                { "label": "K'Erin Colonists"   , "description": "{ \"numbers\": 1, \"panic\": \"1\", \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"A\", \"weapons\": \"2A\" }"                         , "weight": 7  },
                { "label": "Swift War Squad"    , "description": "{ \"numbers\": 2, \"panic\": \"1\", \"speed\": \"6\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"A\", \"weapons\": \"1B\" }"                         , "weight": 5  },
                { "label": "Soulless Task Force", "description": "{ \"numbers\": 0, \"panic\": \"1\", \"speed\": \"4\", \"combat\": \"2\", \"toughness\": \"5\", \"ai\": \"T\", \"weapons\": \"3C\" }"                         , "weight": 4  },
                { "label": "Tech Zealots"       , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"5\", \"combat\": \"0\", \"toughness\": \"5\", \"ai\": \"A\", \"weapons\": \"3C\" }"                       , "weight": 4  },
                { "label": "Colonial Militia"   , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"C\", \"weapons\": \"2B\" }"                       , "weight": 7  },
                { "label": "Planetary Nomads"   , "description": "{ \"numbers\": 2, \"panic\": \"1-2\", \"speed\": \"6\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"C\", \"weapons\": \"2A\" }"                       , "weight": 5  },
                { "label": "Salvage Team"       , "description": "{ \"numbers\": 1, \"panic\": \"1-3\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"C\", \"weapons\": \"2B\" }"                       , "weight": 12 },
            ],
            rovingthreats: [                
                { "label": "Converted Acquisition" , "description": "{ \"numbers\": 1, \"panic\": \"0\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"5\", \"ai\": \"A\", \"weapons\": \"2B\" }"                     , "weight": 5  },
                { "label": "Converted Infiltrators" , "description": "{ \"numbers\": 0, \"panic\": \"0\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"A\", \"weapons\": \"1A\" }"                     , "weight": 8  },
                { "label": "Abductor Raiders"      , "description": "{ \"numbers\": 3, \"panic\": \"1\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"A\", \"weapons\": \"2A\" }"                     , "weight": 6  },
                { "label": "Swarm Brood"           , "description": "{ \"numbers\": 2, \"panic\": \"0\", \"speed\": \"6\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"B\", \"weapons\": \"Claws(Damage +1)\" }"       , "weight": 10 },
                { "label": "Haywire Robots"        , "description": "{ \"numbers\": 2, \"panic\": \"0\", \"speed\": \"3\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"R\", \"weapons\": \"Smash (Damage +0)\" }"      , "weight": 6  },
                { "label": "Razor Lizards"         , "description": "{ \"numbers\": 2, \"panic\": \"1-2\", \"speed\": \"6\", \"combat\": \"1\", \"toughness\": \"3\", \"ai\": \"B\", \"weapons\": \"Fangs (Damage +0)\" }"    , "weight": 10 },
                { "label": "Sand Runners"          , "description": "{ \"numbers\": 1, \"panic\": \"1-2\", \"speed\": \"7\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"B\", \"weapons\": \"Fangs (Damage +1)\" }"    , "weight": 12 },
                { "label": "Void Rippers"          , "description": "{ \"numbers\": 0, \"panic\": \"1-2\", \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"5\", \"ai\": \"R\", \"weapons\": \"Fangs (Damage +0)\" }"    , "weight": 7  },
                { "label": "Krorg"                 , "description": "{ \"numbers\": 0, \"panic\": \"0\", \"speed\": \"5\", \"combat\": \"2\", \"toughness\": \"6\", \"ai\": \"R\", \"weapons\": \"Claws (Damage +2)\" }"      , "weight": 7  },
                { "label": "Large Bugs"            , "description": "{ \"numbers\": 2, \"panic\": \"1\", \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"5\", \"ai\": \"R\", \"weapons\": \"Mandibles (Damage +1)\" }"  , "weight": 9  },
                { "label": "Carnivore Chasers"     , "description": "{ \"numbers\": 2, \"panic\": \"1-2\", \"speed\": \"6\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"B\", \"weapons\": \"Fangs (Damage +0)\" }"    , "weight": 6  },
                { "label": "Vent Crawlers"         , "description": "{ \"numbers\": 0, \"panic\": \"0\", \"speed\": \"6\", \"combat\": \"2\", \"toughness\": \"5\", \"ai\": \"R\", \"weapons\": \"Claws (Damage +0)\" }"      , "weight": 13 },
                { "label": "Distorts"              , "description": "{ \"numbers\": 0, \"panic\": \"0\", \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"B\", \"weapons\": \"Touch (Damage +3)\" }"      , "weight": 3  },
            ],
            uniqueindividuals: [                
                { "label": "Enemy Bruiser"          , "description": "{ \"speed\": \"\", \"combat\": \"\", \"toughness\": \"1\", \"ai\": \"G\", \"luck\": \"0\", \"weapons\": \"Power Claw\" }"                     , "weight": 6  },
                { "label": "Enemy Heavy"            , "description": "{ \"speed\": \"\", \"combat\": \"\", \"toughness\": \"\", \"ai\": \"D\", \"luck\": \"0\", \"weapons\": \"Rattle Gun\" }"                      , "weight": 6  },
                { "label": "Enemy Boss"             , "description": "{ \"speed\": \"\", \"combat\": \"1\", \"toughness\": \"1\", \"ai\": \"\", \"luck\": \"1\", \"weapons\": \"Hand Cannon,Brutal Melee\" }"       , "weight": 5  },
                { "label": "Hired Killer"           , "description": "{ \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"5\", \"ai\": \"A\", \"luck\": \"1\", \"weapons\": \"Machine Pistol,Blade\" }"         , "weight": 5  },
                { "label": "Corporate Spook"        , "description": "{ \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"C\", \"luck\": \"1\", \"weapons\": \"Hand Laser\" }"                   , "weight": 3  },
                { "label": "Bounty Tracker"         , "description": "{ \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"5\", \"ai\": \"A\", \"luck\": \"1\", \"weapons\": \"Shotgun,Blade\" }"                , "weight": 5  },
                { "label": "Callous Merc"           , "description": "{ \"speed\": \"4\", \"combat\": \"2\", \"toughness\": \"5\", \"ai\": \"T\", \"luck\": \"1\", \"weapons\": \"Infantry Laser,Blade\" }"         , "weight": 5  },
                { "label": "Freelancer"             , "description": "{ \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"T\", \"luck\": \"1\", \"weapons\": \"Hand Cannon,Blade\" }"            , "weight": 6  },
                { "label": "Secret Agent"           , "description": "{ \"speed\": \"6\", \"combat\": \"2\", \"toughness\": \"4\", \"ai\": \"A\", \"luck\": \"1\", \"weapons\": \"Hand Laser,Glare Sword\" }"       , "weight": 3  },
                { "label": "Hulker Brawler"         , "description": "{ \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"6\", \"ai\": \"A\", \"luck\": \"0\", \"weapons\": \"Hand Cannon,Brutal Melee\" }"     , "weight": 4  },
                { "label": "Gun Slinger"            , "description": "{ \"speed\": \"5\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"T\", \"luck\": \"1\", \"weapons\": \"Machine Pistol\" }"               , "weight": 5  },
                { "label": "Engineer Tech"          , "description": "{ \"speed\": \"6\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"C\", \"luck\": \"1\", \"weapons\": \"Blast Pistol\" }"                 , "weight": 3  },
                { "label": "Mutant Bruiser"         , "description": "{ \"speed\": \"4\", \"combat\": \"1\", \"toughness\": \"5\", \"ai\": \"G\", \"luck\": \"1\", \"weapons\": \"Shotgun,Brutal Melee\" }"         , "weight": 5  },
                { "label": "Precursor Wanderer"     , "description": "{ \"speed\": \"7\", \"combat\": \"2\", \"toughness\": \"4\", \"ai\": \"A\", \"luck\": \"1\", \"weapons\": \"Infantry Laser,Glare Sword\" }"   , "weight": 4  },
                { "label": "Hakshan Investigator"   , "description": "{ \"speed\": \"5\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"D\", \"luck\": \"1\", \"weapons\": \"Plasma Rifle\" }"                 , "weight": 4  },
                { "label": "K'Erin Warrior"         , "description": "{ \"speed\": \"5\", \"combat\": \"2\", \"toughness\": \"5\", \"ai\": \"A\", \"luck\": \"1\", \"weapons\": \"Machine Pistol,Ripper Sword\" }"  , "weight": 6  },
                { "label": "Nomad Scout"            , "description": "{ \"speed\": \"6\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"D\", \"luck\": \"0\", \"weapons\": \"Marksman's Rifle\" }"             , "weight": 4  },
                { "label": "Cyborg Merc"            , "description": "{ \"speed\": \"7\", \"combat\": \"2\", \"toughness\": \"6\", \"ai\": \"T\", \"luck\": \"1\", \"weapons\": \"Auto Rifle,Power Claw\" }"        , "weight": 3  },
                { "label": "Rogue Psionic"          , "description": "{ \"speed\": \"4\", \"combat\": \"0\", \"toughness\": \"4\", \"ai\": \"C\", \"luck\": \"3\", \"weapons\": \"Hand Gun\" }"                     , "weight": 3  },
                { "label": "Gene Dog"               , "description": "{ \"speed\": \"6\", \"combat\": \"1\", \"toughness\": \"4\", \"ai\": \"G\", \"luck\": \"0\", \"weapons\": \"Fangs (Damage +0)\" }"            , "weight": 6  },
                { "label": "Sand Runner"            , "description": "{ \"speed\": \"7\", \"combat\": \"0\", \"toughness\": \"3\", \"ai\": \"G\", \"luck\": \"0\", \"weapons\": \"Fangs (Damage +1)\" }"            , "weight": 5  },
                { "label": "Mk II Security Bot"     , "description": "{ \"speed\": \"4\", \"combat\": \"2\", \"toughness\": \"5\", \"ai\": \"G\", \"luck\": \"0\", \"weapons\": \"Fury Rifle\" }"                   , "weight": 4  },
            ],
        }
    },
    {
        key: 'enemyweapons',
        title: 'Enemy Weapons',
        sequence: ["weapon1", "weapon2", "weapon3"],
        tables: {
            weapon1: [
                { "label": "Scrap pistol", "weight": 1 },
                { "label": "Hand gun", "weight": 1 },
                { "label": "Colony rifle", "weight": 1 },
                { "label": "Military rifle", "weight": 1 },
                { "label": "Scrap pistol + Blade", "weight": 1 },
                { "label": "Shotgun", "weight": 1 },
            ],
            weapon2: [
                { "label": "Colony rifle", "weight": 1 },
                { "label": "Military rifle", "weight": 3 },
                { "label": "Hand laser", "weight": 1 },
                { "label": "Infantry laser", "weight": 1 },
            ],
            weapon3: [
                { "label": "Hand laser", "weight": 2 },
                { "label": "Infantry laser", "weight": 2 },
                { "label": "Blast rifle", "weight": 1 },                
            ]
        }
    },
    {
        key: 'specialistweapons',
        title: 'Specialist Weapons',
        sequence: ["weaponA", "weaponB", "weaponC"],
        tables: {
            weaponA: [
                { "label": "Power Claw", "weight": 1 },
                { "label": "Shotgun", "weight": 1 },
                { "label": "Auto rifle", "weight": 1 },
                { "label": "Clingfire pistol", "weight": 1 },
                { "label": "Hunting rifle", "weight": 1 },
                { "label": "Hand gun + Ripper sword", "weight": 1 },
            ],
            weaponB: [
                { "label": "Marksman's rifle", "weight": 1 },
                { "label": "Auto rifle", "weight": 1 },
                { "label": "Shell gun", "weight": 1 },
                { "label": "Hand flamer", "weight": 1 },
                { "label": "Rattle gun", "weight": 2 },
            ],
            weaponC: [
                { "label": "Marksman's rifle", "weight": 1 },                
                { "label": "Shell gun", "weight": 1 },
                { "label": "Fury rifle", "weight": 1 },
                { "label": "Plasma rifle", "weight": 2 },
                { "label": "Hyper blaster", "weight": 1 },               
            ]
        }
    },
    {
        key: 'weapons',
        title: 'Weapons',        
        tables: {
            default: [
                { "label": "", "description": "{ \"name\": \"\", \"range\": \"\", \"shots\": \"\", \"damage\": \"\", \"traits\": \"\" }","weight": 1 },
                { "label": "Auto rifle", "description": "{ \"name\": \"Auto rifle\", \"range\": \"24\", \"shots\": \"2\", \"damage\": \"0\", \"traits\": \"-\" }","weight": 1 },
                { "label": "Beam pistol", "description": "{ \"name\": \"Beam pistol\", \"range\": \"10\", \"shots\": \"1\", \"damage\": \"1\", \"traits\": \"Pistol, Critical\" }","weight": 1 },
                { "label": "Blade", "description": "{ \"name\": \"Blade\", \"range\": \"Brawl\", \"shots\": \"-\", \"damage\": \"0\", \"traits\": \"Melee\" }","weight": 1 },   
                { "label": "Blast pistol", "description": "{ \"name\": \"Blast pistol\", \"range\": \"8\", \"shots\": \"1\", \"damage\": \"1\", \"traits\": \"Pistol\" }","weight": 1 },
                { "label": "Blast rifle", "description": "{ \"name\": \"Blast rifle\",\"range\": \"16\", \"shots\": \"1\", \"damage\": \"1\", \"traits\": \"-\" }","weight": 1 },
                { "label": "Boarding saber", "description": "{ \"name\": \"Boarding saber\", \"range\": \"Brawl\", \"shots\": \"-\", \"damage\": \"1\", \"traits\": \"Melee, Elegant\" }","weight": 1 },
                { "label": "Brutal melee weapon", "description": "{ \"name\": \"Brutal melee weapon\", \"range\": \"Brawl\", \"shots\": \"-\", \"damage\": \"1\", \"traits\": \"Melee, Clumsy\" }","weight": 1 },
                { "label": "Cling fire pistol", "description": "{ \"name\": \"Cling fire pistol\", \"range\": \"12\", \"shots\": \"2\", \"damage\": \"1\", \"traits\": \"Focused, Terrifying\" }","weight": 1 },
                { "label": "Colony rifle", "description": "{ \"name\": \"Colony rifle\",\"range\": \"18\", \"shots\": \"1\", \"damage\": \"0\", \"traits\": \"-\" }","weight": 1 },
                { "label": "Dazzle grenade", "description": "{ \"name\": \"Dazzle grenade\", \"range\": \"6\", \"shots\": \"1\", \"damage\": \"NA\", \"traits\": \"Area, Stun, Single use\" }","weight": 1 },
                { "label": "Duelling pistol", "description": "{ \"name\": \"Duelling pistol\", \"range\": \"8\", \"shots\": \"1\", \"damage\": \"0\", \"traits\": \"Pistol, Critical\" }","weight": 1 },
                { "label": "Flak gun", "description": "{ \"name\": \"Flak gun\", \"range\": \"8\", \"shots\": \"2\", \"damage\": \"1\", \"traits\": \"Focused, Critical\" }","weight": 1 },
                { "label": "Frakk grenade", "description": "{ \"name\": \"Frakk grenade\", \"range\": \"6\", \"shots\": \"2\", \"damage\": \"0\", \"traits\": \"Heavy, Area, Single use\" }","weight": 1 },
                { "label": "Fury rifle", "description": "{ \"name\": \"Fury rifle\", \"range\": \"24\", \"shots\": \"1\", \"damage\": \"2\", \"traits\": \"Heavy, Piercing\" }","weight": 1 },
                { "label": "Glare sword", "description": "{ \"name\": \"Glare sword\", \"range\": \"Brawl\", \"shots\": \"-\", \"damage\": \"0\", \"traits\": \"Melee, Elegant, Piercing\" }","weight": 1 },
                { "label": "Hand cannon", "description": "{ \"name\": \"Hand cannon\", \"range\": \"8\", \"shots\": \"1\", \"damage\": \"2\", \"traits\": \"Pistol\" }","weight": 1 },
                { "label": "Hand flamer", "description": "{ \"name\": \"Hand flamer\", \"range\": \"12\", \"shots\": \"2\", \"damage\": \"1\", \"traits\": \"Focused, Aread\" }","weight": 1 },                
                { "label": "Hand gun", "description": "{ \"name\": \"Hand gun\",\"range\": \"12\", \"shots\": \"1\", \"damage\": \"0\", \"traits\": \"Pistol\" }", "weight": 1 },                
                { "label": "Hand laser", "description": "{ \"name\": \"Hand laser\",\"range\": \"12\", \"shots\": \"1\", \"damage\": \"0\", \"traits\": \"Snap Shot, Pistol\" }","weight": 1 },
                { "label": "Hold out pistol", "description": "{ \"name\": \"Hold out pistol\", \"range\": \"4\", \"shots\": \"1\", \"damage\": \"0\", \"traits\": \"Pistol, Melee\" }","weight": 1 },
                { "label": "Hunting rifle", "description": "{ \"name\": \"Hunting rifle\", \"range\": \"30\", \"shots\": \"1\", \"damage\": \"1\", \"traits\": \"Heavy\" }","weight": 1 },
                { "label": "Hyper blaster", "description": "{ \"name\": \"Hyper blaster\", \"range\": \"24\", \"shots\": \"3\", \"damage\": \"1\", \"traits\": \"-\" }","weight": 1 },                
                { "label": "Infantry laser", "description": "{ \"name\": \"Infantry laser\",\"range\": \"30\", \"shots\": \"1\", \"damage\": \"0\", \"traits\": \"Snap Shot\" }","weight": 1 },
                { "label": "Machine pistol", "description": "{ \"name\": \"Machine pistol\", \"range\": \"8\", \"shots\": \"2\", \"damage\": \"0\", \"traits\": \"Pistol focused\" }","weight": 1 },
                { "label": "Marksman's rifle", "description": "{ \"name\": \"Marksman's rifle\", \"range\": \"36\", \"shots\": \"1\", \"damage\": \"0\", \"traits\": \"Heavy\" }","weight": 1 },                
                { "label": "Military rifle", "description": "{ \"name\": \"Military rifle\",\"range\": \"24\", \"shots\": \"1\", \"damage\": \"0\", \"traits\": \"-\" }","weight": 1 },
                { "label": "Needle rifle", "description": "{ \"name\": \"Needle rifle\", \"range\": \"18\", \"shots\": \"2\", \"damage\": \"0\", \"traits\": \"Critical\" }","weight": 1 },
                { "label": "Plasma rifle", "description": "{ \"name\": \"Plasma rifle\", \"range\": \"20\", \"shots\": \"2\", \"damage\": \"1\", \"traits\": \"Focused, Piercing\" }","weight": 1 },
                { "label": "Power claw", "description": "{ \"name\": \"Power claw\", \"range\": \"Brawl\", \"shots\": \"-\", \"damage\": \"3\", \"traits\": \"Melee, Clumsy\" }","weight": 1 },
                { "label": "Rattle gun", "description": "{ \"name\": \"Rattle gun\", \"range\": \"24\", \"shots\": \"3\", \"damage\": \"0\", \"traits\": \"Heavy\" }","weight": 1 },
                { "label": "Ripper Sword", "description": "{ \"name\": \"Ripper sword\",\"range\": \"Brawl\", \"shots\": \"-\", \"damage\":\"1\", \"traits\": \"Melee\" }","weight": 1 },
                { "label": "Scrap pistol", "description": "{ \"name\": \"Scrap pistol\",\"range\": \"9\", \"shots\": \"1\", \"damage\":\"0\", \"traits\": \"Pistol\" }", "weight": 1 },
                { "label": "Shatter axe", "description": "{ \"name\": \"Shatter axe\", \"range\": \"Brawl\", \"shots\": \"-\", \"damage\": \"2\", \"traits\": \"Melee\" }","weight": 1 },
                { "label": "Shell gun", "description": "{ \"name\": \"Shell gun\", \"range\": \"30\", \"shots\": \"2\", \"damage\": \"0\", \"traits\": \"Heavy, Area\" }","weight": 1 },
                { "label": "Shotgun", "description": "{ \"name\": \"Shotgun\",\"range\": \"12\", \"shots\": \"2\", \"damage\":\"1\", \"traits\": \"Focused\" }","weight": 1 }, 
                { "label": "Supression maul", "description": "{ \"name\": \"Supression maul\", \"range\": \"Brawl\", \"shots\": \"-\", \"damage\": \"1\", \"traits\": \"Melee, Impact\" }","weight": 1 },
            ]
        }
    },
]