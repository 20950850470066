export default {
	"options": {
		"patron": "Patron",		
	},	
	"patron": {
		"female": [
			"Interstellar",
			"Agile",
			"Calibrated",
			"Synergistic",
			"Customized",
			"Unified",
			"Galactic",
			"Optimized",
			"Accelerated",
			"Xenomporphic",
			"Diversified",
			"Conglomerated",
			"United",
			"Responsive",
			"Integrated",
			"Universal",
			"Associated",
			"Incorporated",
			"Dynamic",
			"Reactive",
			"Advanced",
			"Optimized",
			"Sector",
			"Extra-solar",
			"Orbital"
		],	
		"surname": [
			"Implementations",
			"Solutions",
			"Sequnces",
			"Aggregates",
			"Enterprises",
			"Deliveries",
			"Composites",
			"Amalgamations",
			"Connections",
			"Executives",
			"Holdings",
			"Acquisitions",
			"Logistics",
			"Resolutions",
			"Defenses",
			"Securities",
			"Procurements",
			"Proficiencies",
			"Resources",
			"Operations",
			"Accounts",
			"Assets",
			"Interactions",
			"Equities",
			"Investments",			
		]
	}
}
