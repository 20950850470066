/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://cnptlahcijevdbukfd7s3dx2qa.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-dyvgiv34wzfonnrsvhcg2fzz7i",
    "aws_cognito_identity_pool_id": "us-east-1:0da23a35-d1db-44ca-9cc7-5a10050bb9ae",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_kQ9kAy2Bx",
    "aws_user_pools_web_client_id": "7hprb41r526ocidc8mogcroh4",
    "oauth": {
        "domain": "voziukm6hgui-production.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:8080,https://develop.d1tp5cd86hfjxz.amplifyapp.com,https://5parsecscompanion.com",
        "redirectSignOut": "http://localhost:8080,https://develop.d1tp5cd86hfjxz.amplifyapp.com,https://5parsecscompanion.com",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ]
};


export default awsmobile;
