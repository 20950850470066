


















































import Vue from 'vue'
import { CampaignStepResult, StepInputType }  from '../js/fiveParsecs';

export default Vue.extend({
  name: "StepDetails",
  props: {
    activeStep: Object as () => { details: CampaignStepResult|null },
  },
  data() {
      return {
        inputTypes: StepInputType,
      }
  }
})
