export default {
	"options": {
		"ship": "Ship",		
	},	
	"ship": {
		"female": [
			"Exuberant",
			"Chivalrous",
			"Dependable",
			"Adventurous",
			"Fierce",
			"Invincible",
			"Plucky",
			"Determined",
			"Majestic",
			"Brave",
			"Independent",
			"Reliable",
			"Proud",
			"Loyal",
			"Furious",
			"Courageous",
			"Ambitious",
			"Regal",
			"Stalwart",
			"Fearsome",
			"Magnificent",
			"Unsurpassable",
			"Inexhaustable",
			"Indispensable",
			"Curious"
		],	
		"surname": [
			"Otter",
			"Traveler",
			"Badger",
			"Hedgehog",
			"Weasel",
			"Dingo",
			"Explorer",
			"Rabbit",
			"Way",
			"Ferrit",
			"Cougar",
			"Pathfinder",
			"Beaver",
			"Mongose",
			"Raccoon",
			"Squirrel",
			"Scout",
			"Tidings",
			"Possum",
			"Hamster",
			"Intruder",
			"Outrider",
			"Hawk",
			"Flacon",
			"Wasp",			
		]
	}
}
